import React, { useEffect } from 'react';
import { Link } from "gatsby"
import { graphql } from 'gatsby';

import { useSiteState } from '../../lib/siteContext';


const PostCard = ({ title, postDate, image, slug, url, category }) => {
  return (
    <div className="col-span-1">
      <div className="relative w-full pb-card-bio bg-doc-text">
      {category === "Media / Press" ? (
        <a href={url} target="_blank" rel="noreferrer">
        {image && <img className="absolute inset-0 w-full h-full object-cover" src={image.asset.url} alt={image.alt}/>}
        </a>        
      ) : (
        <Link to={`/news/${slug.current}`}>
        {image && <img className="absolute inset-0 w-full h-full object-cover" src={image.asset.url} alt={image.alt}/>}
        </Link>        
      )}
      </div>
      <div className="flex justify-between items-baseline my-gutter">
        <span>{category}</span>
        <span>{postDate}</span>
      </div>
      {slug && <Link to={`/news/${slug.current}`} className="heading uppercase text-h6 cursor-pointer">{title}</Link>}
    </div>
  )
}
const CommunityPage = ({ data: { allPosts } }) => {
  const { homeHeader, setHomeHeader } = useSiteState();

  useEffect(() => {
    if (homeHeader === true) {
      setHomeHeader(false);
    }    
    document.documentElement.style.setProperty(`--doc-bg`, '#ffffff');          
     document.documentElement.style.setProperty(`--doc-text`, '#18227B');  
  }, [setHomeHeader, homeHeader]);

  return (
    <>              
      <div className="grid-std pt-40 lg:pt-bannerTop text-fat-blue">
        {/* All Posts */}
        <div id="community" className="col-start-2 col-end-8 lg:col-end-18 mb-space-s">        
          <div className="heading text-h4 lg:text-h3 xl:text-h1 uppercase mb-4 lg:mb-8">COMMUNITY</div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-gutter">
            {allPosts.edges.map((post, index) => (            
              <PostCard {...post.node} category="Community" />
              ))}        
          </div>        
        </div>                                             
      </div>
    </>
  );
};

export default CommunityPage;

export const pageQuery = graphql`
  query communityQuery {            
    allPosts: allSanityArticle(sort: { fields: postDate, order: DESC }, skip: 0) {
      edges {
        node {
          title          
          image {
            asset {
              url
            }
          }          
          postDate(formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
  }
`;
